var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.viewAction.appearance)?_c('div',{staticClass:"my-card-main row"},[_c('div',{staticClass:"my-card-title-main"},[_c('CustomCheckbox',{staticClass:"my-card-title-main-check",attrs:{"isSwitch":false,"value":_vm.isHavePrivlageAllActions,"title":_vm.$t('Privileges.action.modelName')},on:{"changeValue":function($event){_vm.isHavePrivlageAllActions = $event}}})],1),[(_vm.viewAction.appearance)?_c('div',{staticClass:"my-card-main row"},[_c('div',{staticClass:"my-card-title-main"},[_c('CustomCheckbox',{staticClass:"my-card-title-main-check",attrs:{"isSwitch":false,"value":_vm.isHavePrivlageViewAction,"title":_vm.$t('Privileges.action.view')},on:{"changeValue":function($event){_vm.isHavePrivlageViewAction = $event}}})],1),_c('div',{class:_vm.innerClassView},[(_vm.viewAction.activeAppearance)?_c('div',{staticClass:"my-card row"},[_c('div',{staticClass:"my-card-title-main"},[_c('CustomCheckbox',{staticClass:"my-card-title-main-check",attrs:{"isSwitch":false,"value":_vm.isHavePrivlageViewActionActive,"title":_vm.$t('Privileges.active')},on:{"changeValue":function($event){_vm.isHavePrivlageViewActionActive = $event}}})],1),_vm._l((_vm.viewAction.active),function(funcation,activeIndex){return _c('CustomCheckbox',{key:("" + (funcation.funcationWithModuleToken) + activeIndex),attrs:{"isSwitch":false,"className":'col-md-12',"value":funcation.funcationPrivilegeStatus,"title":("" + (funcation.funcationNameCurrent))},on:{"changeValue":function($event){funcation.funcationPrivilegeStatus = $event;
              _vm.emitAtLeastOneActions();}}})})],2):_vm._e()]),_c('div',{class:_vm.innerClassView},[(_vm.viewAction.archivedAppearance)?_c('div',{staticClass:"my-card row"},[_c('div',{staticClass:"my-card-title-main"},[_c('CustomCheckbox',{staticClass:"my-card-title-main-check",attrs:{"isSwitch":false,"value":_vm.isHavePrivlageViewActionArchived,"title":_vm.$t('Privileges.archived')},on:{"changeValue":function($event){_vm.isHavePrivlageViewActionArchived = $event}}})],1),_vm._l((_vm.viewAction.archived),function(funcation,archivedIndex){return _c('CustomCheckbox',{key:("" + (funcation.funcationWithModuleToken) + archivedIndex),attrs:{"isSwitch":false,"className":'col-md-12',"value":funcation.funcationPrivilegeStatus,"title":("" + (funcation.funcationNameCurrent))},on:{"changeValue":function($event){funcation.funcationPrivilegeStatus = $event;
              _vm.emitAtLeastOneActions();}}})})],2):_vm._e()]),_c('div',{class:_vm.innerClassView},[(_vm.viewAction.blockedAppearance)?_c('div',{staticClass:"my-card row"},[_c('div',{staticClass:"my-card-title-main"},[_c('CustomCheckbox',{staticClass:"my-card-title-main-check",attrs:{"isSwitch":false,"value":_vm.isHavePrivlageViewActionBlocked,"title":_vm.$t('Privileges.blocked')},on:{"changeValue":function($event){_vm.isHavePrivlageViewActionBlocked = $event}}})],1),_vm._l((_vm.viewAction.blocked),function(funcation,blockedIndex){return _c('CustomCheckbox',{key:("" + (funcation.funcationWithModuleToken) + blockedIndex),attrs:{"isSwitch":false,"className":'col-md-12',"value":funcation.funcationPrivilegeStatus,"title":("" + (funcation.funcationNameCurrent))},on:{"changeValue":function($event){funcation.funcationPrivilegeStatus = $event;
              _vm.emitAtLeastOneActions();}}})})],2):_vm._e()])]):_vm._e()],[(_vm.makeAction.appearance)?_c('div',{staticClass:"my-card-main row"},[_c('div',{staticClass:"my-card-title-main"},[_c('CustomCheckbox',{staticClass:"my-card-title-main-check",attrs:{"isDisabled":!_vm.isOneOfViewActionTrue,"isSwitch":false,"value":_vm.isHavePrivlageMakeAction,"title":_vm.$t('Privileges.action.make')},on:{"changeValue":function($event){_vm.isHavePrivlageMakeAction = $event}}})],1),_c('div',{class:_vm.innerClassMake},[(_vm.makeAction.addAppearance)?_c('div',{staticClass:"my-card row"},[_c('div',{staticClass:"my-card-title-main"},[_c('CustomCheckbox',{staticClass:"my-card-title-main-check",attrs:{"isDisabled":!_vm.isOneOfViewActionTrue,"isSwitch":false,"value":_vm.isHavePrivlageMakeActionAdd,"title":_vm.$t('Privileges.action.add')},on:{"changeValue":function($event){_vm.isHavePrivlageMakeActionAdd = $event}}})],1),_vm._l((_vm.makeAction.add),function(funcation,addIndex){return _c('CustomCheckbox',{key:("" + (funcation.funcationWithModuleToken) + addIndex),attrs:{"isDisabled":!_vm.isOneOfViewActionTrue,"isSwitch":false,"className":'col-md-12',"value":funcation.funcationPrivilegeStatus,"title":("" + (funcation.funcationNameCurrent))},on:{"changeValue":function($event){funcation.funcationPrivilegeStatus = $event;
              _vm.emitAtLeastOneActions();}}})})],2):_vm._e()]),_c('div',{class:_vm.innerClassMake},[(_vm.makeAction.updateAppearance)?_c('div',{staticClass:"my-card row"},[_c('div',{staticClass:"my-card-title-main"},[_c('CustomCheckbox',{staticClass:"my-card-title-main-check",attrs:{"isDisabled":!_vm.isOneOfViewActionTrue,"isSwitch":false,"value":_vm.isHavePrivlageMakeActionUpdate,"title":_vm.$t('Privileges.action.update')},on:{"changeValue":function($event){_vm.isHavePrivlageMakeActionUpdate = $event}}})],1),_vm._l((_vm.makeAction.update),function(funcation,updateIndex){return _c('CustomCheckbox',{key:("" + (funcation.funcationWithModuleToken) + updateIndex),attrs:{"isDisabled":!_vm.isOneOfViewActionTrue,"isSwitch":false,"className":'col-md-12',"value":funcation.funcationPrivilegeStatus,"title":("" + (funcation.funcationNameCurrent))},on:{"changeValue":function($event){funcation.funcationPrivilegeStatus = $event;
              _vm.emitAtLeastOneActions();}}})})],2):_vm._e()]),_c('div',{class:_vm.innerClassMake},[(_vm.makeAction.deleteAppearance)?_c('div',{staticClass:"my-card row"},[_c('div',{staticClass:"my-card-title-main"},[_c('CustomCheckbox',{staticClass:"my-card-title-main-check",attrs:{"isDisabled":!_vm.isOneOfViewActionTrue,"isSwitch":false,"value":_vm.isHavePrivlageMakeActionDelete,"title":_vm.$t('Privileges.action.delete')},on:{"changeValue":function($event){_vm.isHavePrivlageMakeActionDelete = $event}}})],1),_vm._l((_vm.makeAction.delete),function(funcation,deleteIndex){return _c('CustomCheckbox',{key:("" + (funcation.funcationWithModuleToken) + deleteIndex),attrs:{"isDisabled":!_vm.isOneOfViewActionTrue,"isSwitch":false,"className":'col-md-12',"value":funcation.funcationPrivilegeStatus,"title":("" + (funcation.funcationNameCurrent))},on:{"changeValue":function($event){funcation.funcationPrivilegeStatus = $event;
              _vm.emitAtLeastOneActions();}}})})],2):_vm._e()]),_c('div',{class:_vm.innerClassMake},[(_vm.makeAction.imageAppearance)?_c('div',{staticClass:"my-card row"},[_c('div',{staticClass:"my-card-title-main"},[_c('CustomCheckbox',{staticClass:"my-card-title-main-check",attrs:{"isDisabled":!_vm.isOneOfViewActionTrue,"isSwitch":false,"value":_vm.isHavePrivlageMakeActionImage,"title":_vm.$t('Privileges.action.image')},on:{"changeValue":function($event){_vm.isHavePrivlageMakeActionImage = $event}}})],1),_vm._l((_vm.makeAction.image),function(funcation,imageIndex){return _c('CustomCheckbox',{key:("" + (funcation.funcationWithModuleToken) + imageIndex),attrs:{"isDisabled":!_vm.isOneOfViewActionTrue,"isSwitch":false,"className":'col-md-12',"value":funcation.funcationPrivilegeStatus,"title":("" + (funcation.funcationNameCurrent))},on:{"changeValue":function($event){funcation.funcationPrivilegeStatus = $event;
              _vm.emitAtLeastOneActions();}}})})],2):_vm._e()])]):_vm._e()],[(_vm.generalMakeAction.appearance)?_c('div',{staticClass:"my-card-main row"},[_c('div',{staticClass:"my-card-title-main"},[_c('CustomCheckbox',{staticClass:"my-card-title-main-check",attrs:{"isDisabled":!_vm.canGeneralMakeAction,"isSwitch":false,"value":_vm.isHavePrivlageGeneralMakeAction,"title":_vm.$t('Privileges.action.general')},on:{"changeValue":function($event){_vm.isHavePrivlageGeneralMakeAction = $event}}})],1),_c('div',{class:_vm.innerClassGeneralMake},[(_vm.generalMakeAction.activeAppearance)?_c('div',{staticClass:"my-card row"},[_c('div',{staticClass:"my-card-title-main"},[_c('CustomCheckbox',{staticClass:"my-card-title-main-check",attrs:{"isDisabled":!_vm.canGeneralMakeActive,"isSwitch":false,"value":_vm.isHavePrivlageGeneralMakeActionActive,"title":_vm.$t('Privileges.active')},on:{"changeValue":function($event){_vm.isHavePrivlageGeneralMakeActionActive = $event}}})],1),_vm._l((_vm.generalMakeAction.active),function(funcation,activeIndex){return _c('CustomCheckbox',{key:("" + (funcation.funcationWithModuleToken) + activeIndex),attrs:{"isDisabled":!_vm.canGeneralMakeActive,"isSwitch":false,"className":'col-md-12',"value":funcation.funcationPrivilegeStatus,"title":("" + (funcation.funcationNameCurrent))},on:{"changeValue":function($event){funcation.funcationPrivilegeStatus = $event;
              _vm.emitAtLeastOneActions();}}})})],2):_vm._e()]),_c('div',{class:_vm.innerClassGeneralMake},[(_vm.generalMakeAction.archivedAppearance)?_c('div',{staticClass:"my-card row"},[_c('div',{staticClass:"my-card-title-main"},[_c('CustomCheckbox',{staticClass:"my-card-title-main-check",attrs:{"isDisabled":!_vm.canGeneralMakeArchived,"isSwitch":false,"value":_vm.isHavePrivlageGeneralMakeActionArchived,"title":_vm.$t('Privileges.archived')},on:{"changeValue":function($event){_vm.isHavePrivlageGeneralMakeActionArchived = $event}}})],1),_vm._l((_vm.generalMakeAction.archived),function(funcation,archivedIndex){return _c('CustomCheckbox',{key:("" + (funcation.funcationWithModuleToken) + archivedIndex),attrs:{"isDisabled":!_vm.canGeneralMakeArchived,"isSwitch":false,"className":'col-md-12',"value":funcation.funcationPrivilegeStatus,"title":("" + (funcation.funcationNameCurrent))},on:{"changeValue":function($event){funcation.funcationPrivilegeStatus = $event;
              _vm.emitAtLeastOneActions();}}})})],2):_vm._e()]),_c('div',{class:_vm.innerClassGeneralMake},[(_vm.generalMakeAction.blockedAppearance)?_c('div',{staticClass:"my-card row"},[_c('div',{staticClass:"my-card-title-main"},[_c('CustomCheckbox',{staticClass:"my-card-title-main-check",attrs:{"isDisabled":!_vm.canGeneralMakeBlocked,"isSwitch":false,"value":_vm.isHavePrivlageGeneralMakeActionBlocked,"title":_vm.$t('Privileges.blocked')},on:{"changeValue":function($event){_vm.isHavePrivlageGeneralMakeActionBlocked = $event}}})],1),_vm._l((_vm.generalMakeAction.blocked),function(funcation,blockedIndex){return _c('CustomCheckbox',{key:("" + (funcation.funcationWithModuleToken) + blockedIndex),attrs:{"isDisabled":!_vm.canGeneralMakeBlocked,"isSwitch":false,"className":'col-md-12',"value":funcation.funcationPrivilegeStatus,"title":("" + (funcation.funcationNameCurrent))},on:{"changeValue":function($event){funcation.funcationPrivilegeStatus = $event;
              _vm.emitAtLeastOneActions();}}})})],2):_vm._e()])]):_vm._e()]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }